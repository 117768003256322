export enum GA_EVENTS {
   SIGN_UP = 'sign_up',
   SIGN_OUT = 'sign_out',
   SIGN_IN = 'sign_in',
   PROFILE_SELECTION = 'profile_selection',
   WATCHLIST_ADD = 'watchlist_add',
   WATCHLIST_REMOVE = 'watchlist_remove',
   RAIL_SLIDE_CHANGE = 'rail_slide_change',
   RAIL_ITEM_CLICK = 'rail_item_click',
   CONTENT_INFO = 'content_info',
   CONTENT_CLICK = 'content_click',
   CONTENT_PLAY = 'content_play',
   SEARCH_QUERY = 'search_query',
   SEARCH_RESULT_CLICK = 'search_result_click',
   PARENTAL_CONTROL_TOGGLE = 'parental_control_toggle',
   ADD_PROFILE = 'add_profile',
   EDIT_PROFILE = 'edit_profile',
   PARENTAL_MATURITY_RATING = 'parental_maturity_rating',
   PIN_CHANGE = 'pin_change',
   CHANGE_PASSWORD = 'change_password',
   CHANGE_EMAIL = 'change_email',
   MANAGE_PLAN = 'manage_plan',
   PAGE_ERROR = 'page_error',
   PLAYBACK_ERROR = 'playback_error',
   GEO_BLOCK = 'geo_block',
}
