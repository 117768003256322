// redux core
import { createSelector } from 'reselect'
import type { ReduxState } from '@/store/redux'

// helper
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'

// types
import { TContactMessage } from './user.d'
import { USER_TYPES } from '@/middleware.d'

const selectConfig = (state: ReduxState) => state.config

export const selectUser = (state: ReduxState) => state.user

export const selectUserRole = (state: ReduxState) => state.user.userRole

export const selectProfiles = (state: ReduxState) => state.user.profiles

export const selectUserIsGuest = (state: ReduxState) => state.user.userRole === USER_TYPES.UNSUBSCRIBED

export const selectIsUserProfileFetched = createSelector([selectUser], (user) => {
   const { fetchProfileAsyncStatus } = user
   return fetchProfileAsyncStatus === 'complete'
})

export const selectIsHavingUserProfiles = createSelector([selectUser], (user) => {
   const { profiles } = user

   return profiles.length > 0
})

export const selectUserProfiles = createSelector([selectConfig, selectUser], (config, user) => {
   const {
      details: { avatar },
   } = config
   const { profiles } = user
   let tailoredProfiles = []
   for (let i = 0; i <= CommonHelper.CONSTANTS.PROFILE_COUNT - 1; i++) {
      tailoredProfiles.push({
         ...profiles[i],
         picUrl: getTransformedImageUrl(
            avatar?.baseUrl + avatar?.images[profiles[i]?.picUrl as any],
            CommonHelper.IMAGE_SIZES['profile'].high.width,
            CommonHelper.IMAGE_SIZES['profile'].high.height,
            CommonHelper.IMAGE_SIZES['profile'].high.borderRadius,
            true,
         ),
         picUrlFilename: profiles[i]?.picUrl,
      })
   }
   return tailoredProfiles
})

export const selectActiveUserProfile = createSelector([selectUserProfiles], (profiles) => {
   const activeProfile = profiles?.filter((profile) => profile.isActiveProfile === true)[0]
   return {
      ...activeProfile,
   }
})

export const selectUserProfilesWithNoRatings = createSelector([selectUserProfiles], (profiles) => {
   const profilesNeeded = profiles?.filter(
      (profile) =>
         profile.parentalControl === true && (!('parentalControlLevel' in profile) || !profile?.parentalControlLevel),
   )
   const profileWithNoRating = profilesNeeded.map((profile) => ({
      profileId: profile.ID,
      picUrlFilename: profile.picUrlFilename,
   }))
   return profileWithNoRating
})

export const selectHeaderUserName = createSelector([selectActiveUserProfile], (activeProfile) => {
   let username = activeProfile.firstName
   return { ellipsisedName: username.length > 10 ? username.slice(0, 10) + '...' : username, regularName: username }
})

export const selectSortedProfileList = createSelector([selectUserProfiles], (profiles) => {
   const allAssociatedProfiles = profiles.filter((profile) => profile.isActiveProfile === false)

   for (let profile of allAssociatedProfiles) {
      profile.ellipsisedFirstName =
         profile.firstName.trim().length > 10 ? profile.firstName.trim().slice(0, 10) + '...' : profile.firstName
   }

   return (allAssociatedProfiles || []).length > 0
      ? allAssociatedProfiles!
           .slice()
           .sort((profileA: any, profileB: any) =>
              profileA.contactCreateDate.toString().localeCompare(profileB.contactCreateDate.toString()),
           )
      : []
})

// account page profile module
export const selectAllProfiles = createSelector([selectConfig, selectUser], (config, user) => {
   const {
      details: { avatar },
   } = config
   const { profiles } = user
   return profiles?.map((profile: TContactMessage) => ({
      id: profile.ID,
      name: profile.firstName + ' ' + profile.lastName,
      firstName: profile.firstName,
      avatarkey: profile.picUrl,
      contactCreateDate: profile.contactCreateDate,
      isPrimaryContact: profile.isPrimaryContact,
      autoPlayNextEpisode: profile.autoPlayNextEpisode,
      picUrl: getTransformedImageUrl(
         avatar?.baseUrl + avatar?.images[profile.picUrl as any],
         CommonHelper.IMAGE_SIZES['profile'].high.width,
         CommonHelper.IMAGE_SIZES['profile'].high.height,
         CommonHelper.IMAGE_SIZES['profile'].high.borderRadius,
         true,
      ),
      picUrlFilename: profile.picUrl,
      parentalControl: profile.parentalControl,
      parentalControlLevel: profile.parentalControlLevel,
      a11yParentalControlLevelParent: 'TV-MA|R'.split('|').join(',')?.replace(/-/g, ' hyphen '),
      a11yParentalControlLevelChildren: profile.parentalControlLevel?.split('|')?.join(',')?.replace(/-/g, ' hyphen '),
      contactID: profile.contactID,
   }))
})

export const selectProfileOverflow = createSelector([selectUser], (user) => {
   const { profiles } = user
   return profiles.length > CommonHelper.CONSTANTS.PROFILE_COUNT - 1
})

export const selectInitialAvatarFetchStatus = (state: ReduxState) => state.user.initialAvatarAsyncStatus

export const selectInitialAvatar = createSelector([selectUser], (user) => {
   const { initialAvatar } = user
   return initialAvatar
})

export const selectAvatars = createSelector([selectConfig], (config) => {
   const {
      details: { avatar },
   } = config
   return { avatar }
})

export const selectHasApiError = (state: ReduxState) => state.user.hasApiError
export const selectFetchProfileAsyncStatus = (state: ReduxState) => state.user.fetchProfileAsyncStatus
export const selectSignOutStatus = (state: ReduxState) => state.user.signOutStatus

const updateConsolidatedObject = (consolidatedObject: { [key: string]: any }, category: string, property: any) => {
   const selectedIndex = property.selected // Use the selected index to determine the right value

   switch (property.label) {
      case 'Color':
         if (category === 'Text') {
            consolidatedObject.textColor = property.options[selectedIndex][0]
         } else if (category === 'Text Background') {
            consolidatedObject.textBackgroundColor = property.options[selectedIndex][0]
         } else if (category === 'Caption Area Background') {
            consolidatedObject.captionBackgroundColor = property.options[selectedIndex][0]
         }
         break
      case 'Opacity':
         if (category === 'Text') {
            consolidatedObject.textOpacity = property.options[selectedIndex][0]
         } else if (category === 'Text Background') {
            consolidatedObject.textBackgroundOpacity = property.options[selectedIndex][0]
         } else if (category === 'Caption Area Background') {
            consolidatedObject.captionBackgroundOpacity = property.options[selectedIndex][0]
         }
         break
      case 'Font Size':
         consolidatedObject.textSize = property.options[selectedIndex][0]
         break
      case 'Text Edge Style':
         consolidatedObject.textEdge = property.options[selectedIndex][0]
         break
      default:
         break
   }
}

// Selector to generate consolidated object from the active profile's text track configuration
export const selectConsolidatedTextTrackSetting = createSelector([selectProfiles], (profiles) => {
   const activeProfile = profiles.find((profile) => profile.isActiveProfile)
   const textTrackConfiguration = activeProfile?.textTrackConfiguration

   const consolidatedObject: { [key: string]: any } = {}

   if (textTrackConfiguration) {
      textTrackConfiguration.forEach((settings: any) => {
         settings.property.forEach((property: any) => {
            updateConsolidatedObject(consolidatedObject, settings.title, property)
         })
      })
   }

   return consolidatedObject
})

export const selectSubscription = createSelector([selectUser], (user) => {
   const { subscription } = user
   return subscription
})
export const selectFetchSubscriptionAsyncStatus = (state: ReduxState) => state.user.fetchSubscriptionAsyncStatus

export const selectPaymentMethods = createSelector([selectUser], (user) => {
   const { paymentMethods } = user
   return paymentMethods
})
export const selectPaymentMethodAsyncStatus = (state: ReduxState) => state.user.fetchPaymentMethodAsyncStatus
export const selectReasonCodes = (state: ReduxState) => state.user.getReasonCodes
export const selectRemoveSubscriptionAsyncStatus = (state: ReduxState) => state.user.removeSubscriptionAsyncStatus
export const selectRemoveSubscriptionResponse = (state: ReduxState) => state.user.removeSubscriptionResponse
export const selectPrimaryAccount = createSelector([selectUserProfiles], (profiles) => {
   const primaryAccount = profiles?.filter((profile) => profile.isPrimaryContact === true)
   return primaryAccount
})
export const selectCustomerId = (state: ReduxState) => state.user.customerId
export const selectSubscriptionType = (state: ReduxState) => state.user.subscriptionType
