// redux core
import type { ReduxState } from '@/store/redux'
import { createSelector } from 'reselect'

export const selectWatchlist = (state: ReduxState) => state.watchlist
export const selectWatchedListAssets = (state: ReduxState) => state.watchlist.watchedListAssetsInPromoRail
export const selectIsInWatchlistStatus = (state: ReduxState) => state.watchlist.isInWatchlistStatus

export const selectIsInwatchlist = (state: ReduxState) => state.watchlist.isInWatchlist

export const selectWatchlistAllFetched = (state: ReduxState) => state.watchlist.watchlistAllFetched

export const selectFetchWatchlistStatus = (state: ReduxState) => state.watchlist.watchlistCollectionStatus
export const selectFetchWatchlistAsyncError = (state: ReduxState) => state.watchlist.watchlistCollectionAsyncError

export const selectAddToWatchlistStatus = (state: ReduxState) => state.watchlist.addToWatchlistStatus
export const selectAddToWatchlistAsyncError = (state: ReduxState) => state.watchlist.addToWatchlistAsyncError

export const selectRemoveFromWatchlistStatus = (state: ReduxState) => state.watchlist.removeFromWatchlistStatus
export const selectRemoveFromWatchlistAsyncError = (state: ReduxState) => state.watchlist.removeFromWatchlistAsyncError

export const selectComputedWatchlist = createSelector([selectWatchlist], (watchlistRoot) => {
   const { watchlistCollection } = watchlistRoot
   return watchlistCollection
})

export const selectWatchlistKeys = createSelector([selectWatchlist], (watchlistRoot) => {
   const { watchlistKeys } = watchlistRoot
   let keyArray = []
   for (let [key, value] of Object.entries(watchlistKeys)) {
      keyArray.push([key, value])
   }

   return keyArray
})
