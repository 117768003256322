import { Navigation, Pagination, A11y, FreeMode, Mousewheel, Autoplay } from 'swiper/modules'

export const RAIL_MAX_LENGTH = 20
export const SHOW_NEW_LABEL_FROM_PSSD_LENGTH = 30

const COLOR_WHITE = ['#FFFFFF', 'White']
const COLOR_BLACK = ['#000000', 'Black']
const COLOR_RED = ['#FF0000', 'Red']
const COLOR_GREEN = ['#00ff00', 'Green']
const COLOR_BLUE = ['#0000ff', 'Blue']
const COLOR_YELLOW = ['#ffff00', 'Yellow']

const OPACITY_OPAQUE = ['1', 'Opaque']
const OPACITY_SEMI = ['0.5', 'Semi-Transparent']
const OPACITY_TRANS = ['0', 'Transparent']

const EDGE_NONE = ['none', 'None']
const EDGE_RAISED = ['raised', 'Raised']
const EDGE_DEPRESSED = ['depressed', 'Depressed']
const EDGE_UNIFORM = ['uniform', 'Uniform']
const EDGE_DROPSHADOW = ['dropshadow', 'Dropshadow']

const SIZE_SMALL = ['1.875rem', 'Small']
const SIZE_MEDIUM = ['2.25rem', 'Medium']
const SIZE_LARGE = ['3.125rem', 'Large']

export const showViewAll = (elementLength: number) => {
   return elementLength >= RAIL_MAX_LENGTH
}

export const RAIL_CONFIG = (railType: string, pageOfUse: string) => {
   let configuration: { [key: string]: any } = {
      'feed': {
         'hero_carousel': {
            config: {
               a11y: true,
               speed: 900,
               autoplay: {
                  delay: 10000,
                  disableOnInteraction: true,
               },
               spaceBetween: 0,
               slidesPerView: 1,

               pagination: {
                  clickable: true,
               },
               navigation: true,
               modules: [Navigation, Pagination, A11y, Autoplay],
            },
         },
         'continue_watching': {
            config: {
               a11y: false,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               navigation: true,
               watchOverflow: true,
               slidesPerGroupAuto: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesPerView: 'auto',
               spaceBetween: 5,
               breakpoints: {
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'episodic_rail': {
            config: {
               a11y: false,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               navigation: true,
               watchOverflow: true,
               slidesPerGroupAuto: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesPerView: 'auto',
               spaceBetween: 5,
               breakpoints: {
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'poster_horizontal': {
            config: {
               a11y: false,
               effect: 'fade',
               slidesPerView: 'auto',
               slidesPerGroupAuto: true,
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'poster_vertical': {
            config: {
               a11y: false,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'genre': {
            config: {
               a11y: false,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
      },
      'movie': {
         'continue_watching': {
            config: {
               a11y: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesPerView: 'auto',
               spaceBetween: 5,
               breakpoints: {
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'poster_horizontal': {
            config: {
               a11y: true,
               effect: 'fade',
               slidesPerView: 'auto',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'poster_vertical': {
            config: {
               a11y: true,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'genre': {
            config: {
               a11y: true,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
      },
      'series': {
         'continue_watching': {
            config: {
               a11y: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesPerView: 'auto',
               spaceBetween: 5,
               breakpoints: {
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'episodic_rail': {
            config: {
               a11y: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               navigation: true,
               watchOverflow: true,
               slidesPerGroupAuto: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesPerView: 'auto',
               spaceBetween: 5,
               breakpoints: {
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'poster_horizontal': {
            config: {
               a11y: true,
               effect: 'fade',
               slidesPerView: 'auto',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'poster_vertical': {
            config: {
               a11y: true,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
         'genre': {
            config: {
               a11y: true,
               effect: 'fade',
               freeMode: true,
               mousewheel: {
                  forceToAxis: true,
               },
               speed: 500,
               slidesPerGroupAuto: true,
               navigation: true,
               watchOverflow: true,
               modules: [A11y, FreeMode, Mousewheel, Navigation],
               autoHeight: true,
               slidesOffsetBefore: 40,
               slidesOffsetAfter: 40,
               breakpoints: {
                  640: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  768: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
                  1024: {
                     slidesPerView: 'auto',
                     spaceBetween: 5,
                  },
               },
            },
         },
      },
   }

   if (railType in configuration[pageOfUse]) {
      return configuration[pageOfUse][railType]
   } else {
      return {
         config: {
            a11y: true,
            slidesPerView: 1,
            spaceBetween: 0,
            freeMode: true,
            pagination: true,
            mousewheel: {
               forceToAxis: true,
            },
            speed: 500,
            slidesPerGroupAuto: true,
            navigation: true,
            watchOverflow: true,
            modules: [A11y, FreeMode, Mousewheel, Navigation],
         },
      }
   }
}
// Data Resource To Render UI Component
export const TRACK_SETTING_CONFIG_KEY = 'text-track-settings'
export const TRACK_SETTING_CONFIG: Readonly<any> = [
   {
      title: 'Text',
      property: [
         {
            label: 'Color',
            options: [COLOR_WHITE, COLOR_BLACK, COLOR_RED, COLOR_GREEN, COLOR_BLUE, COLOR_YELLOW],
            selected: 0,
         },
         {
            label: 'Opacity',
            options: [OPACITY_OPAQUE, OPACITY_SEMI],
            selected: 0,
         },
         {
            label: 'Font Size',
            options: [SIZE_SMALL, SIZE_MEDIUM, SIZE_LARGE],
            selected: 1,
         },
         {
            label: 'Text Edge Style',
            options: [EDGE_NONE, EDGE_RAISED, EDGE_DEPRESSED, EDGE_UNIFORM, EDGE_DROPSHADOW],
            selected: 0,
         },
      ],
   },
   {
      title: 'Text Background',
      property: [
         {
            label: 'Color',
            options: [COLOR_WHITE, COLOR_BLACK, COLOR_RED, COLOR_GREEN, COLOR_BLUE, COLOR_YELLOW],
            selected: 1,
         },
         {
            label: 'Opacity',
            options: [OPACITY_OPAQUE, OPACITY_SEMI, OPACITY_TRANS],
            selected: 0,
         },
      ],
   },
   {
      title: 'Caption Area Background',
      property: [
         {
            label: 'Color',
            options: [COLOR_WHITE, COLOR_BLACK, COLOR_RED, COLOR_GREEN, COLOR_BLUE, COLOR_YELLOW],
            selected: 0,
         },
         {
            label: 'Opacity',
            options: [OPACITY_OPAQUE, OPACITY_SEMI, OPACITY_TRANS],
            selected: 2,
         },
      ],
   },
]

// Data Resource To Update the CSS Styling
export const INITIAL_TRACK_SETTINGS_KEY = 'track_settings_key'
export const INITIAL_TRACK_SETTINGS = {
   txtColor: COLOR_WHITE[0],
   txtOpacity: OPACITY_OPAQUE[0],
   txtSize: SIZE_MEDIUM[0],
   txtEdgeStyle: EDGE_NONE[0],
   txtBgColor: COLOR_BLACK[0],
   txtBgOpacity: OPACITY_OPAQUE[0],
   caBgColor: COLOR_WHITE[0],
   caBgOpacity: OPACITY_TRANS[0],
}
