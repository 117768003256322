// redux core
import { createLogger } from 'redux-logger'

// queue middleware
import queueMiddleware from './queueMiddleware'

// slices
import {
   billingHistoryApiSlice,
   parentalControlPinApiSlice,
   logoutApiSlice,
   geoLocAvailabilityApiSlice,
} from './slices'

const isReduxDebuggingON = process.env.NEXT_PUBLIC_REDUX_DEBUG === '1'

const middlewareLogger = createLogger({
   duration: true,
   timestamp: false,
   collapsed: true,
   colors: {
      title: () => '#139BFE',
      prevState: () => '#1C5FAF',
      action: () => '#149945',
      nextState: () => '#A47104',
      error: () => '#ff0005',
   },
   predicate: () => typeof window !== 'undefined',
})

const middleware = [
   ...(isReduxDebuggingON ? [middlewareLogger] : []),
   billingHistoryApiSlice.middleware,
   parentalControlPinApiSlice.middleware,
   logoutApiSlice.middleware,
   geoLocAvailabilityApiSlice.middleware,
]

export { middleware, queueMiddleware }
