// helper
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

// utility
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'

// type
import { TContinueWatchingRail, ICardItem } from './type'

// images
import movieFallback from '@/public/images/card-movie-fallback.png'
import seriesFallback from '@/public/images/card-series-fallback.png'

/**
 * ContinueWatchingHelper helps generate continue watching related data
 * and manage relavant calculations before storing declarative data into redux store.
 */
export default class ContinueWatchingHelper {
   commonHelper!: CommonHelper
   informationPageHelper: InformationPageHelper
   title: string = 'Continue Watching'
   type: string = 'continue_watching'
   item = {} as ICardItem
   elements = [] as ICardItem[]
   isInViewDetection = false
   assetIds = [] as Number[]
   showViewAll = false
   playlistId = ''
   preCompiledDetail = {} as TContinueWatchingRail

   constructor(continuousWatchingDetail: TContinueWatchingRail) {
      this.preCompiledDetail = continuousWatchingDetail
      this.title = this.preCompiledDetail?.title
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      this.showViewAll = this.preCompiledDetail?.showViewAll as boolean
      this.playlistId = this.preCompiledDetail?.playlistId as string
      this.type =
         this.preCompiledDetail.rail_type === 'episodic_rail'
            ? CommonHelper.RAIL_VARIANT.EPISODIC_RAIL
            : CommonHelper.RAIL_VARIANT.CONTINUE_WATCHING
   }

   /**
    * Generates fallback image per content type
    * @param {string} contentType - content type for conditional cases
    * @returns {StaticImageData} - fallback image src object
    */
   computeFallbackImage(contentType: string) {
      return contentType === CommonHelper.CONTENT_TYPE.EPISODE ? seriesFallback.src : movieFallback.src
   }

   /**
    * Generates designated image per content type along with dimensions
    * @param {object} railItem - individual rail item from rail collection
    * @returns {object} Compiled, UI declarative collection of image and dimension
    */
   computeImage(railItem: { [key: string]: any }) {
      let dynamicImage = ''
      let dynamicImageDimension: { width: number; height: number; borderRadius: number } = {
         width: 0,
         height: 0,
         borderRadius: 0,
      }

      dynamicImageDimension = {
         width: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
         height: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
         borderRadius: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
      }
      if (railItem.content_type?.toLowerCase() === 'episode') {
         dynamicImage = getTransformedImageUrl(
            railItem?.images?.still_frame ?? railItem?.images?.h_poster,
            CommonHelper.IMAGE_SIZES['still_frame'].high.width,
            CommonHelper.IMAGE_SIZES['still_frame'].high.height,
            CommonHelper.IMAGE_SIZES['still_frame'].high.borderRadius,
         )
      } else {
         dynamicImage = getTransformedImageUrl(
            railItem?.images?.h_poster,
            CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
            CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
            CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
         )
      }

      return { 'image': dynamicImage, 'dimension': dynamicImageDimension }
   }

   /**
    * Generate expected link that encompasses respective rail card
    * @param {object} railItem - individualrail item from rail collection
    * @returns {string} calculated link
    *
    * TODO: add 98% check
    */
   computeLink(railItem: { [key: string]: any }, railType: string = CommonHelper.RAIL_VARIANT.EPISODIC_RAIL) {
      let calculatedLink = ''
      if (railItem.content_type === CommonHelper.CONTENT_TYPE.MOVIE) {
         if (railType === CommonHelper.RAIL_VARIANT.EPISODIC_RAIL) {
            // this handle behaviour of movie card in Episodic rail.
            return `/movies/${railItem?.video_id}`
         } else {
            // this handle behaviour of movie card in continue watching rail.
            calculatedLink = `/watch?video=${railItem.video_id}`
         }
      } else if (railItem.content_type === CommonHelper.CONTENT_TYPE.EPISODE) {
         if (this.commonHelper.isValidPlayBackStartDate(railItem.playback_start_date)) {
            calculatedLink = `/watch?video=${railItem.video_id}`
         } else {
            return `/series/${railItem?.series_ref}?isref=true`
         }
      } else if (railItem.content_type === CommonHelper.CONTENT_TYPE.SERIES) {
         return `/series/${railItem?.video_id}`
      } else {
         return ''
      }
      return calculatedLink
   }

   /**
    * Generate expected category and label for respective rail card
    * @param {string} playbackStartDate - day when streaming start
    * @param {string} playbackEndDate - day when streaming end
    * @returns {object} Compiled, UI declarative collection of label and category
    */
   computeTimeline(playbackStartDateTime: string, playbackEndDateTime: string, comingSoonDateTime?: string) {
      const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(
         playbackStartDateTime,
         playbackEndDateTime,
         comingSoonDateTime,
      )
      let label = ''
      let category = ''

      if (
         timeDifference.comingSoonDifference >= 0 &&
         timeDifference.startDifference <= 0 &&
         this.type === CommonHelper.RAIL_VARIANT.EPISODIC_RAIL
      ) {
         category = CommonHelper.BADGE_TYPE.COMINGSOON
         label = `COMING SOON`
      } else {
         const remainingDuration = Math.abs(timeDifference.endDifference)
         if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
            category = CommonHelper.BADGE_TYPE.NEW
            label = `NEW`
         }
         if (
            remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            remainingDuration <=
               this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = `LEAVING IN  ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
         } else if (
            remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = 'LEAVING TODAY'
         }
      }
      return { category, label }
   }

   computeMetaLink = (railItem: { [key: string]: any }, railType: string = CommonHelper.RAIL_VARIANT.EPISODIC_RAIL) => {
      if (railItem.content_type === CommonHelper.CONTENT_TYPE.MOVIE) {
         return `/movies/${railItem?.video_id}`
      } else if (railItem.content_type === CommonHelper.CONTENT_TYPE.EPISODE) {
         return `/series/${railItem?.series_ref}?isref=true`
      } else {
         return ''
      }
   }

   /**
    * Calculates with api response to create tailored data
    * for rendering.
    */
   tailoredCollection(railType?: string) {
      const compiledElements = []
      const continueWatching = this.preCompiledDetail.elements
      let railLength = continueWatching.length
      if (continueWatching.length === CommonHelper.CONSTANTS.RAIL_MAX_LENGTH) {
         railLength = continueWatching.length + 1
      }
      for (let railIndex = 0; railIndex < continueWatching.length; railIndex++) {
         if (
            railType === CommonHelper.RAIL_VARIANT.EPISODIC_RAIL ||
            railIndex < CommonHelper.CONSTANTS.RAIL_MAX_LENGTH
         ) {
            const { image, dimension } = this.computeImage(continueWatching[railIndex])

            const primaryText = continueWatching[railIndex].series_title || continueWatching[railIndex].name
            const secondaryText =
               continueWatching[railIndex].content_type === CommonHelper.CONTENT_TYPE.EPISODE
                  ? `S${continueWatching[railIndex].season_number}E${continueWatching[railIndex].episode_no} · ${continueWatching[railIndex].name}`
                  : ''

            const ariaLabelRemainingTime = this.commonHelper.computeAriaRemainingTime(
               this.informationPageHelper.calculateTimeLeft(
                  continueWatching[railIndex].progress,
                  continueWatching[railIndex].duration,
               ),
            )

            this.item = {
               primaryText,
               secondaryText,
               contentType: this.type,
               videoId: continueWatching[railIndex].video_id,
               card: {
                  link: this.computeLink(continueWatching[railIndex], railType) ?? '',
                  metaLink: this.computeMetaLink(continueWatching[railIndex], railType),
                  linkAriaLabel: ` ${primaryText}, ${secondaryText}, ${ariaLabelRemainingTime} , ${
                     railIndex + 1
                  } of ${railLength}`,
                  picture: image ? image : this.computeFallbackImage(continueWatching[railIndex].content_type),
                  dimension,
                  title: ` ${continueWatching[railIndex].series_title}  S${
                     continueWatching[railIndex].season_number
                  } E${continueWatching[railIndex].episode_no}  ·  ${continueWatching[railIndex].name}, ${
                     railIndex + 1
                  } of ${continueWatching.length}`,
                  isPlayable: this.commonHelper.isValidPlayBackStartDate(
                     continueWatching[railIndex].playback_start_date,
                  ),
                  progress: (continueWatching[railIndex].progress / continueWatching[railIndex].duration) * 100,
                  isLazyloadDisabled: false,
                  remainingTime: this.informationPageHelper.calculateTimeLeft(
                     continueWatching[railIndex].progress,
                     continueWatching[railIndex].duration,
                  ),
                  fallbackSrc: this.computeFallbackImage(continueWatching[railIndex].content_type),
                  timelineAnnouncement: this.computeTimeline(
                     continueWatching[railIndex].playback_start_date,
                     continueWatching[railIndex].playback_end_date,
                     continueWatching[railIndex]?.coming_soon_date,
                  ),
                  isContentExpired: CommonHelper.moment(continueWatching[railIndex].playback_end_date)
                     .utc()
                     .isBefore(CommonHelper.moment().utc()),
                  playbackEndDate: continueWatching[railIndex].playback_end_date,
                  rating: continueWatching[railIndex]?.rating,
                  contentType:
                     continueWatching[railIndex]?.content_type === 'episode'
                        ? 'series'
                        : continueWatching[railIndex]?.content_type,
                  contentId: String(continueWatching[railIndex]?.video_id),
                  contentRef: String(
                     continueWatching[railIndex]?.series_ref || continueWatching[railIndex]?.movie_ref || '',
                  ),
                  genres: Array.isArray(continueWatching[railIndex]?.genre)
                     ? (continueWatching[railIndex]?.genre as Array<string>)?.join(',')
                     : (continueWatching[railIndex]?.genre as string)?.trim() ?? '',
               },
            }

            compiledElements.push(this.item)
         }
         if (
            railType === CommonHelper.RAIL_VARIANT.EPISODIC_RAIL &&
            this.preCompiledDetail.showViewAll &&
            railIndex === continueWatching.length - 1
         ) {
            this.item = {
               primaryText: '',
               secondaryText: '',
               contentType: this.type,
               card: {
                  link: `/viewall?title=${encodeURIComponent(this.title)}&type=eh&id=${this.playlistId}`,
                  metaLink: '',
                  linkAriaLabel: `${railLength} of ${railLength}, View All`,
                  picture: '/dummy.jpg',
                  dimension: {
                     width: 0,
                     height: 0,
                     borderRadius: 0,
                  },
                  title: 'View All',
                  isPlayable: false,
                  progress: 0,
                  isLazyloadDisabled: true,
                  remainingTime: '',
                  fallbackSrc: '',
                  rating: '',
                  contentType: '',
               },
            }

            compiledElements.push(this.item)
            break
         }
      }

      return {
         title: this.title,
         type: this.type,
         elements: compiledElements,
         isInViewDetection: this.isInViewDetection,
      }
   }
}
